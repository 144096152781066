<template>
  <div class="section">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Detalle del usuario</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a @click="returnView()" class="button button-givu">Regresar</a>
        </div>
        <div class="level-item">
        </div>
      </div>
    </nav>
    <div class="columns is-multiline is-centered">
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-8">
            <div class="box">
              <customers-detail
                :customer="customer"
                :addGroup="addGroup"
                :actionResendEmail="actionResendEmail"
              />
            </div>
          </div>
          <div class="column is-4">
            <div class="box">
              <CustomersTransactions
                :productCount="parseInt(customer.total_products)"
                :givs="customer.balance"
                :sold="parseInt(sold)"
                :purchases="parseInt(customer.total_purchases) || 0"
                :totalReferreds="parseInt(customer.total_referreds) || 0"
                :addGivs="addGivs"
                :modalAddGivs="modalAddGivs"
                :showModal="showModal"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-8">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="box">
              <customers-products
                :products="products"
                :page="page"
                :totalProducts="parseInt(customer.total_products || 1)"
                :loading="loading"
                :paginator="getCustomerProducts"
              />
            </div>
          </div>
          <div class="column is-12">
            <div class="box">
              <customer-purchased-products
                :products="purchasedProducts"
                :page="purchasedPage"
                :totalProducts="parseInt(totalPurchasedProducts)"
                :loading="loading"
                :paginator="getCustomerPurchasedProducts"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="box">
              <customers-referred
                :referreds="referreds"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Chat
      v-if="customer.id && customer.id !== 4"
      :userId="customer.id"
      :nameUser="custom"
      :username="customer.username"
      :userImage="customer.userImage"
      :chatData="chatData"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DetalleDelUsuario',
  components: {
    CustomersDetail: () => import('@/components/Customers/Detail'),
    CustomersTransactions: () => import('@/components/Customers/Transactions'),
    CustomersProducts: () => import('@/components/Customers/Products'),
    CustomerPurchasedProducts: () => import('@/components/Customers/PurchasedProducts'),
    CustomersReferred: () => import('@/components/Customers/Referred'),
    Chat: () => import('@/components/Chat')
  },
  data () {
    return {
      loading: false,
      customer: {},
      products: [],
      productStatus: 1,
      purchasedProducts: [],
      customerId: this.$route.params.userId,
      sold: 0,
      page: 1,
      purchasedPage: 1,
      totalPurchasedProducts: 1,
      referreds: [],
      modalAddGivs: false,
      chatData: {},
      custom: null
    }
  },
  watch: {
    $route (to, from) {
      this.loading = true
      this.customerId = to.params.userId
      this.getUserInfoDetail()
    }
  },
  methods: {
    ...mapActions(['getCustomerById', 'getCustomerProductsById', 'getCustomerPurchasedProductsById', 'addGivsToUser', 'addUserToGroup', 'getChatId', 'forwardMail']),
    getCustomerProducts (page, status) {
      this.page = page
      if (status) this.productStatus = status
      this.getCustomerProductsById({ customerId: this.customerId, productStatus: this.productStatus, page })
        .then(response => {
          this.products = response
        })
    },
    getCustomerPurchasedProducts (page) {
      this.purchasedPage = page
      this.getCustomerPurchasedProductsById({ customerId: this.customerId, page })
        .then(response => {
          this.purchasedProducts = response
        })
    },
    showModal () {
      this.modalAddGivs = !this.modalAddGivs
    },
    addGivs (amount) {
      this.addGivsToUser({ userId: this.customerId, amount })
        .then(response => {
          if (response) {
            this.getCustomerById({ customerId: parseInt(this.customerId), productStatus: this.productStatus })
              .then(response => {
                const { soldProducts, userReferred, ...customerInfo } = response
                this.customer = customerInfo
                this.showModal()
              })
          }
        })
    },
    async addGroup (groupId) {
      const response = await this.addUserToGroup({ userId: this.customerId, groupId })
      const customer = await this.getCustomerById({ customerId: parseInt(this.customerId), productStatus: this.productStatus })
      const { soldProducts, userReferred, ...customerInfo } = customer
      this.customer = customerInfo
      this.sold = soldProducts
      this.referreds = userReferred
      return response
    },
    returnView () {
      this.$router.go(-1)
    },
    async actionResendEmail (id) {
      this.loading = true
      await this.forwardMail(id)
    },
    async getUserInfoDetail () {
      this.getCustomerById({ customerId: parseInt(this.customerId), productStatus: this.productStatus })
        .then(response => {
          const { soldProducts, userReferred, ...customerInfo } = response
          this.customer = customerInfo
          this.sold = soldProducts
          this.referreds = userReferred
          this.getCustomerProductsById({ customerId: this.customerId, productStatus: this.productStatus, page: this.page })
            .then(response => {
              this.products = response
              this.loading = false
            })
          this.getCustomerPurchasedProductsById({ customerId: this.customerId, page: this.purchasedPage })
            .then(response => {
              this.purchasedProducts = response.products
              this.totalPurchasedProducts = response.countPurchasedProducts
              this.loading = false
            })
        })
      this.getChatId(parseInt(this.customerId))
        .then(resp => {
          this.chatData = resp.payload
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getUserInfoDetail()
  }
}
</script>

<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
  }
  .title {
    border-bottom: 1px solid #F0F0F0;
  }
</style>
